.header {
    /* align to conainter */
    grid-column-start: 2;
    grid-column-end: 3;

    /* header grid */
    display: grid;
    grid-template-columns: 70% auto;
    grid-template-rows: auto;

    /* font settings header */
    line-height: 5rem;
    font-size: 3rem;
    
    /* align vertically */
    align-self: end;

    /* spacing */
    padding-top: 20px;

    /* bottom line header */
    border-bottom: 1px solid black;

    /* transition */
    transition: all .15s;
    
    /* align "about" */
    &__nav {
        justify-self: end;
    } 
    
    /* ABOUT */

    /* align to full width, invert colors */
    &--active {
        grid-column-start: 1;
        grid-column-end: 4;
        background-color: black;
        color: white;
        padding-bottom: 4rem;
    }
    /* mark 'about' italic */
    &--active-about {
        font-style: italic;
    }

    &__bio {
        font-size: 1.6rem;
        margin: 0;
        padding: 0;
        display: none;
        line-height: 2rem;
        max-width: 55rem;
    }

    
}
.p-container {
    padding-left: 2.5vw;
    padding-right: 2.5vw;

}
._show {
    display: none!important;
    opacity: 0;
}
.about {
    transition: all .35s;
    opacity: 1;
    color: white;
    /* align to container */
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    /* full width */
    grid-column-start: 1;
    grid-column-end: 3;
    grid-gap: 3rem;
    /* subgrid */
    display: grid;
    grid-template-columns: 1fr;
    @mixin atTablet {
        grid-template-columns: 1fr 1fr;
    }
    grid-template-rows: auto;
    /* font settings */
    font-size: 1.6rem;
    line-height: 2.25rem;
    letter-spacing: 0.01rem;

    &__bio {
        max-width: 55rem;
        margin-bottom: 6rem;
        p:first-child { margin: 0; }
        p:first-of-type::first-line { text-indent: 0rem; }
        p::first-line { text-indent: 2rem; }
    }
    &__exhibitions {
        margin-bottom: 1rem;
        cursor: default;
        p {
            margin: 0;
            
        }
        
    }
    &__contact {
        align-self: end;
        @mixin atTablet {
            margin-bottom: 0;
        }
        margin-bottom: 6rem;
        h3 { letter-spacing: 0; }
    }

    h3 {
        font-weight: inherit;
        font-size: 3rem;
        margin: 0;
        
    }

    ul {
        padding: 0;
        margin: 0;
        text-indent: 0;
        list-style-type: none;
        border-top: 1px solid white;
        margin-top: 1rem;
    }

    li {
        padding: 0.2rem 0;
        font-size: 1.45rem;
        display: grid;
        grid-template-columns: 3rem 1fr;
        @mixin atTablet {
            grid-template-columns: 3rem 11rem 1fr;
        }
        grid-gap: 2rem;
        transition: color .15s;
    }
    li:hover {color: rgb(180, 180, 180) }
    li:first-child { margin-top: 1rem; }
    li p { margin-block-start: 0; margin-block-end: 0;}

    &__legal {
        @mixin atTablet {
            justify-self: end;
            align-self: end;
        }
    }


}
.past {
    opacity: 1;
}
.exhibition-design {
    display: none!important;
}
._hide_atPhone {
    display: none;
    @mixin atTablet {
        display: inline;
    }
}