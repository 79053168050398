.bigger-font {
    font-size: 3rem;
}
.no-indent::first-line {
    text-indent: 0!important;
    
}
.no-indent {
    font-size: 1.1rem;
    line-height: 1.5rem;
}