/* content container, global grid */
.container {
    display: grid;
    /* side margins */
    grid-template-columns: 2.5vw auto 2.5vw;
    /* global rows:
    Header
    Current
    Portfolio-Title
    Portfolio
    Footer
    */
    grid-template-rows: minmax(70px, auto) auto auto auto auto;

    /* default font-size = 16px */
    font-size: 1.6rem;
}