/* media breakpoints */

@define-mixin atPhone {
	@media (min-width: 576px) {
		@mixin-content;
	}
}

@define-mixin atTablet {
	@media (min-width: 992px) {
		@mixin-content;
	}
}

@define-mixin atDesktop {
	@media (min-width: 1200px) {
		@mixin-content;
	}
}

@define-mixin atHD {
	@media (min-width: 1900px) {
		@mixin-content;
	}
}
