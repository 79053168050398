.current {
    /* align to conainter */
    grid-column-start: 2;
    grid-column-end: 3;

    /* current grid */
    display: grid;
    /* mobile first */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto);
    /* global grid gap */
    grid-gap: 2rem;
    @mixin atTablet { grid-gap: 3rem; }
    /* tablet and above */
    @mixin atTablet { grid-template-columns: repeat(4, 1fr); }

    /* spacing settings */
    padding-top: 2rem;
    padding-bottom: 4rem;

    /* bottom line current */
    border-bottom: 1px solid black;

    /* category font settings */
    h1 {
        font-size: 1.6rem;
        font-weight: normal;
        display: inline;
    }

    &_date {
        letter-spacing: 0.035em;
    }
    :nth-child(3) {
        grid-column-start: 2;
    }
    
    @mixin atTablet {
    :nth-child(3) { grid-column-start: 3; } }
}
/* TO DO! align settings */
/* label always in the same column */