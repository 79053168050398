/* 
Font-Family
Suisse International
Regular, Book and Bold
by Swiss Typefaces
 */
@font-face {
	font-family: SI-Reg;
	font-style: normal;
	font-weight: normal;
	src: url(./fonts/SuisseIntl-Regular-Web/SuisseIntl-Regular-WebS.woff2) format('woff2'),
	url(./fonts/SuisseIntl-Regular-Web/SuisseIntl-Regular-WebS.woff) format('woff'),
	url(./fonts/SuisseIntl-Regular-Web/SuisseIntl-Regular-WebS.eot) format('embedded-opentype'),
	url(./fonts/SuisseIntl-Regular-Web/SuisseIntl-Regular-WebS.ttf) format('ttf'),
	url(./fonts/SuisseIntl-Regular-Web/SuisseIntl-Regular-WebS.svg) format('svg');
}
@font-face {
	font-family: SI-Reg;
	font-style: italic;
	font-weight: normal;
	src: url(./fonts/SuisseIntl-RegularItalic-Web/SuisseIntl-RegularItalic-WebS.woff2) format('woff2'),
	url(./fonts/SuisseIntl-RegularItalic-Web/SuisseIntl-RegularItalic-WebS.woff) format('woff'),
	url(./fonts/SuisseIntl-RegularItalic-Web/SuisseIntl-RegularItalic-WebS.eot) format('embedded-opentype'),
	url(./fonts/SuisseIntl-RegularItalic-Web/SuisseIntl-RegularItalic-WebS.ttf) format('ttf'),
	url(./fonts/SuisseIntl-RegularItalic-Web/SuisseIntl-RegularItalic-WebS.svg) format('svg');
}
@font-face {
	font-family: SI-Reg;
	font-style: normal;
	font-weight: bold;
	src: url(./fonts/SuisseIntl-Bold-WebS/SuisseIntl-Bold-WebS.woff2) format('woff2'),
	url(./fonts/SuisseIntl-Bold-WebS/SuisseIntl-Bold-WebS.woff) format('woff'),
	url(./fonts/SuisseIntl-Bold-WebS/SuisseIntl-Bold-WebS.eot) format('embedded-opentype'),
	url(./fonts/SuisseIntl-Bold-WebS/SuisseIntl-Bold-WebS.ttf) format('ttf'),
	url(./fonts/SuisseIntl-Bold-WebS/SuisseIntl-Bold-WebS.svg) format('svg');
}
@font-face {
	font-family: SI-Reg;
	font-style: italic;
	font-weight: bold;
	src: url(./fonts/SuisseIntl-BoldItalic-WebS/SuisseIntl-BoldItalic-WebS.woff2) format('woff2'),
	url(./fonts/SuisseIntl-BoldItalic-WebS/SuisseIntl-BoldItalic-WebS.woff) format('woff'),
	url(./fonts/SuisseIntl-BoldItalic-WebS/SuisseIntl-BoldItalic-WebS.eot) format('embedded-opentype'),
	url(./fonts/SuisseIntl-BoldItalic-WebS/SuisseIntl-BoldItalic-WebS.ttf) format('ttf'),
	url(./fonts/SuisseIntl-BoldItalic-WebS/SuisseIntl-BoldItalic-WebS.svg) format('svg');
}
/* global font settings */
html {
	/* This defines what 1 rem is; 1rem = 10px; 10px/16px = 62.5% */
	font-size: 62.5%;
	font-family: SI-Reg;
	color: black;
	-webkit-font-smoothing: antialiased;
}
/* global */
/* please move into modules */
a {
    text-decoration: none;
	color: inherit;
	/* transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); */

}
.no-scroll {
	overflow:hidden;
}
/* a:hover {

}
img {
    transition: transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
img:hover {
    transform: scale(0.95); 
} 
*/