.newsletter {
    overflow-y: auto;
	overflow-x: hidden;
    position: fixed;
    z-index: 2;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.05);
    transition: opacity .3s, transform .3s, visibility .3s;
    box-sizing: border-box;

    &--active {
        background-color:rgba(255, 255, 255, 0.9);
        visibility: visible;
        opacity: 1;
        transform: scale(1);  
    }

    &__form {
        width: 700px;
        height: 400px;
        position: absolute;
        background-color: white;
        border-radius: 30px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
    &__test {
        position: absolute;
        left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%);
    }
}