.gallery {
    overflow-y: auto;
	overflow-x: hidden;
    position: fixed;
    z-index: 2;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.02);
    transition: opacity .3s, transform .3s, visibility .3s;
    box-sizing: border-box;
    
    &--active {
        /* background-color: rgba(217, 217, 217, 1); */
        background-color:rgba(255, 255, 255, 0.965);
        visibility: visible;
        opacity: 1;
        transform: scale(1);  
    }

    &__img {
        position: absolute;
        max-height: 80vh;
        max-width: 80vw;
        left: 50%;
        transform: translateX(-50%);
    }

    /* Navigation */
    .gallery__nav {
        width: 17rem;
        height: 3.5rem;
        background-color: black;
        border-radius: 20px;
        /* font settings */
        color: white;
        font-size: 1.6rem;
        /* grid */
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;
        /* position */
        position: fixed;
        bottom: 3rem;
        left: 50vw;
        transform: translateX(-50%);
        transition: .15s;
        /* cursor */
        cursor: pointer;
        &--prev {

        }
        &--close {

        }
        &--next {

        }
        &--hide {
            opacity: 0;
            visibility: hidden;
        }
    }
}
.body-no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }