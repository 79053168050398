.portfolio {
    /* align to conainter */
    grid-column-start: 2;
    grid-column-end: 3;

    /* portfolio grid */
    display: grid;
    /* mobile first: 2 projects per row */
    grid-template-columns: repeat(2, 1fr);
    /* infinite rows */
    grid-template-rows: repeat(auto);
    /* global grid gap */
    grid-gap: 2rem;
    @mixin atTablet { grid-gap: 3rem; }
    /* tablet: 3 projects per row */
    @mixin atTablet { grid-template-columns: repeat(3, 1fr); }
    /* desktop and above: 4 projects per row */
    @mixin atDesktop { grid-template-columns: repeat(4, 1fr); }

    /* spacing settings */
    padding-top: 1rem;
    /* space after portfolio section */
    padding-bottom: 10rem;

    /* portfolio element / project */
    &__item { 
        /* projects are aligned at the top row line */
        align-self: top;
        padding-top: 1rem;
    }
    /* 
    project image
    */
    &__item img {
        /* fit image to same size always */
        width: 100%;
        height: auto;
        object-fit: cover;
        /* hover scale transition */
        transition: transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    /* &__item img:hover { transform: scale(0.995); } */
    &__item__description p {
        /* reset p spacing values */
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0;
        line-height: 2.15rem;
    }
    &__item__description p:first-child {
        /* space between text and image */
        margin-block-start: 1em;
    }
    &__item__description--category {
        /* project category */
        font-style: italic;
    }
    /* 
    portfolio label in separate row before portfolio div
    */
    &__label {
        /* align to conainter */
        grid-column-start: 2;
        grid-column-end: 3;
        padding-top: 2rem;      
    }
}

