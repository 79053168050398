.impressum {
    grid-column-start: 2;
    grid-column-end: 3;
    /* spacing settings */
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: 55rem;
    h1 { font-weight: inherit; font-size: 2rem;}
    h2 { font-weight: inherit; font-size: 2rem;}
    h3 { font-weight: inherit; font-size: 2rem;}
}