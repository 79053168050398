.project {
    /* align to conainter */
    grid-column-start: 2;
    grid-column-end: 3;
    /* project grid */
    display: grid;
    /* mobile first: 1 column */
    grid-template-columns: 100%;
    /* infinite rows */
    grid-template-rows: auto;
    /* areas responive */
    grid-template-areas:
    "head"
    "body";

    /* Tablet and above: 2 columns: 60% images, 40% Text */
    @mixin atTablet { 
        grid-template-columns: repeat(12, 1fr);
    }
    /* spacing settings */
    padding-top: 4rem;
    /* COLUMN ONE */
    /* images */
    &__images {
        /* align to 12 column grid */
        /* mobile fist: full width */
        grid-column-start: 1;
        grid-column-end: 13;
        /* at tablet: 7/12 width */
        @mixin atTablet {
            grid-column-start: 1;
            grid-column-end: 8;
        }
        /* images grid */
        display: grid;
        /* two same sized image columns */
        grid-template-columns: auto auto;
        /* infinite rows */
        grid-template-rows: auto;
        /* global grid gap */
        grid-gap: 2rem;
        @mixin atTablet { grid-gap: 3rem; }
        
        
        @mixin atTablet { max-width: 66vh; }
        
        /* grey class for publication images */
        &--grey {
            background-color: rgba(217, 217, 217, 0.658);
            padding: 1em 1em;
        }

        /* space after last image */
        margin-bottom: 50px;
        @mixin atTablet {
            /* tablet and above*/
            margin-bottom: 200px;
        }
    }
    
    /* images layout system */
    &__images img {
        width: 100%;
        height: auto;
        object-fit: cover;
        /* no drag and no select */
        user-drag: none; 
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        /* enter gallery */
        cursor: pointer;
    }
    /* default image width is one column  */
    /* large: two column width */
    &__images--large {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    /* one column image, aligned left, one column white space */
    &__images--large--half {
        grid-column-start: 1;
        grid-column-end: 3;
        max-width: 50%;
    }
    /* one column image, aligned right, one column white space */
    &__images--large--half--right {
        grid-column-start: 1;
        grid-column-end: 3;
        max-width: 50%;
        justify-self: end;
    }
    


    /* COLUMN TWO */
    /* text */
    &__text {
        /* align to 12 column grid */
        /* mobile first: full width */
        grid-column-start: 1;
        grid-column-end: 13;
        @mixin atTablet {
            grid-column-start: 8;
            grid-column-end: 13;
        }
        @mixin atTablet {
            padding-left: 5rem;
        }
        /* global text section font-settings */
        hyphens: auto;
        /* margin-bottom */
        margin-bottom: 20rem;
        @mixin atTablet { margin-bottom: 0;}
        /* tablet and above: justify right */
        @mixin atTablet { justify-self: end; }
        /* max width - align with perfect reading lenth */
        max-width: 55rem;
    }
    &__text__title {
        /* project title */
        font-size: 3rem;
        
    }
    &__text__category {
        margin-top: 2rem;
    }
    &__text__text {
        /* spacing settings */
        padding-left: 0rem;
        margin-top: 5rem;
        
        /* font settings */
        line-height: 2.25rem;
    }
    &__text__text p {
        margin-block-start: 1.5rem;
        margin-block-end: 0rem;
    }
    /* text indent first line feature */
    &__text__text p:first-of-type::first-line { text-indent: 0rem; }
    &__text__text p::first-line { text-indent: 2rem; }

    a {
        display: inline-block;
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    a:hover {
        transform: translateX(1rem);
    }
}