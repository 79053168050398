.progress {

    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;

    &__container {
        width: 100%;
        height: 8px;
        background: white;
    }

    &__bar {
        height: 8px;
        background: black;
        width: 0%;
    }
}