.footer {
    grid-column-start: 2;
    grid-column-end: 3;

    font-size: 0.75rem;

    padding-top: 1rem;
    padding-bottom: 2rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    a {
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    a:hover {
        display: inline-block;
        transform: translateX(0.25rem);
        color: rgba(0, 0, 0, 0.35)!important;
    }

    &__item {
        margin-bottom: 1.5rem;
    }
    &__item:last-child {
        margin-bottom: 0;
    }
}