.btn {
    position: fixed;
    /* center */
    left: 50%;
    transform: translateX(-50%);
    /* hide */
    bottom: -100px;
    /* style */
    display: inline-block;
    padding: 1.1rem 1.9rem;
    background-color: black;
    color: white;
    /* border */
    border-radius: 1rem;
    border-color: black;
    /* font */
    font-size: 1.8rem;
    /* link */
    cursor: pointer;
    
    transition: bottom 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    outline: none;
}
.btn:hover {
    background-color: white;
    color: black;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);

}
.btn:active {
    background-color: white;
    border-color: black;
    color: black;
}
.btn:focus{
    background-color: white;
    border-color: black;
    color: black;
}
.btn--return {
    bottom: 50px;
}